import React from 'react';
import { Helmet } from 'react-helmet';
import { Hero } from '@latitude/hero';
import { Lframe } from '@latitude/lframe';
import { BREAKPOINT, LFRAME_TYPE } from '@latitude/core/utils/constants';
import { List, ListItem } from '@latitude/list';
import RibbonSection from '@/components/Section/RibbonSection';
import Layout from '@/components/layout';
import lframeBGImage from './images/lframe-blue-gradient.svg';

function TermsAndConditionsPage(props) {
  return (
    <Layout location={props.location}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/terms-and-conditions/"
        />
        <title>
          Terms & Conditions | Latitude 28&deg; Global Platinum Mastercard |
          International Credit Card
        </title>
        <meta
          name="desciption"
          content="View the Latitude 28&deg; Global terms and conditions of use when using the site and applying for a credit card."
        />
      </Helmet>

      <main>
        <div
          css={`
            position: relative;
          `}
        >
          <Lframe
            bgImage={lframeBGImage}
            type={LFRAME_TYPE.LIGHT_BLUE}
            simple
            css={`
              max-height: 180px;
              background-position: 100% 0;
              background-size: contain;

              @media (min-width: ${BREAKPOINT.SM}) {
                background-position: top right;
                background-size: contain;
              }
              @media (min-width: ${BREAKPOINT.LG}) {
                max-height: 270px;
              }
            `}
          />
          <Hero
            simple
            title="Terms and Conditions"
            css={`
              max-height: 180px;
              min-height: 180px;
              background-color: #e8f4ff;

              .HeroContent {
                padding: 64px 0;
              }

              @media (min-width: ${BREAKPOINT.LG}) {
                max-height: 270px;
                min-height: 270px;

                .HeroContent {
                  padding: 100px 0;
                }
              }
            `}
          />
        </div>

        <RibbonSection id="content">
          <p>
            Please read these terms carefully before using this site. This Web
            site is provided by Latitude Financial Services Australia Holdings
            Pty Ltd (ABN 46 6030 160 827) and Latitude Finance Australia (ABN 42
            008 583 588) ("together Latitude"). By using the site or downloading
            materials from the site, you agree to abide by the terms and
            conditions set forth in this notice. If you do not agree to abide by
            these terms and conditions do not use the site or download materials
            from the site.
          </p>
          <h5>Limited licence</h5>
          <p>
            Subject to the terms and conditions set forth in this Agreement,
            Latitudegrants you a non-exclusive, non-transferable, limited right
            to access, use and display this site and the materials thereon. You
            agree not to interrupt or attempt to interrupt the operation of the
            site in any way. Latitude authorises you to view and download the
            information ("Materials") at this Web site ("Site") only for your
            personal, non-commercial use. This authorisation is not a transfer
            of title in the Materials and copies of the Materials and is subject
            to the following restrictions:
          </p>
          <List variant="ol">
            <ListItem>
              you must retain, on all copies of the Materials downloaded, all
              copyright and other proprietary notices contained in the
              Materials;
            </ListItem>
            <ListItem>
              you may not modify the Materials in any way or reproduce or
              publicly display, perform, or distribute or otherwise use them for
              any public or commercial purpose; and
            </ListItem>
            <ListItem>
              you must not transfer the Materials to any other person unless you
              give them notice of, and they agree to accept, the obligations
              arising under these terms and conditions of use. You agree to
              abide by all additional restrictions displayed on the Site as it
              may be updated from time to time. This Site, including all
              Materials, is copyrighted and protected by worldwide copyright
              laws and treaty provisions. You agree to comply with all copyright
              laws worldwide in your use of this Site and to prevent any
              unauthorised copying of the Materials. Except as expressly
              provided herein, Latitude Financial Services does not grant any
              express or implied right to you under any patents, trademarks,
              copyrights or trade secret information.
            </ListItem>
          </List>

          <h5>Disclaimer</h5>
          <p>
            The materials may contain inaccuracies and typographical errors.
            Latitude does not warrant the accuracy or completeness of the
            materials or the reliability of any advice, opinion, statement or
            other information displayed or distributed through the site. You
            acknowledge that any reliance on any such opinion, advice,
            statement, memorandum, or information shall be at your sole risk.
            Latitude Financial Services reserves the right, in its sole
            discretion, to correct any errors or omissions in any portion of the
            site. latitude financial services may make any other changes to the
            site, the materials and the products, programs, services or prices
            (if any) described in the site at any time without notice. subject
            to any applicable provisions of the Trade Practices Act 1974 (cth),
            which cannot be modified or excluded, this site, the information and
            materials on the site, and the software made available on the site,
            are provided "as is" without any representation or warranty, express
            or implied, of any kind, including, but not limited to, warranties
            of merchantability, noninfringement, or fitness for any particular
            purpose.
          </p>

          <h5>Third-Party sites</h5>
          <p>
            As a convenience to you, Latitude may provide, on this Site, links
            to Web sites operated by other entities. If you use these sites, you
            will leave this Site. If you decide to visit any linked site, you do
            so at your own risk and it is your responsibility to take all
            protective measures to guard against viruses or other destructive
            elements. Latitude makes no warranty or representation regarding,
            and does not endorse, any linked Web sites or the information
            appearing thereon or any of the products or services described
            thereon. Links do not imply that Latitude or this Site sponsors,
            endorses, is affiliated or associated with, or is legally authorised
            to use any trademark, trade name, logo or copyright symbol displayed
            in or accessible through the links, or that any linked site is
            authorised to use any trademark, trade name, logo or copyright
            symbol of Latitude or any of its affiliates or subsidiaries.
          </p>

          <h5>Information provided by you</h5>
          <p>
            Unless you are applying online or using the Online Service Centre,
            Latitude does not want you to, and you should not, send any
            confidential or proprietary information to Latitude via the Site.
            You agree that any such information or materials that you or
            individuals acting on your behalf provide to Latitude will not be
            considered confidential or proprietary. By providing any such
            information or materials to Latitude, you grant to Latitude an
            unrestricted, irrevocable, worldwide, royalty-free license to use,
            reproduce, display, publicly perform, transmit and distribute such
            information and materials, and you further agree that Latitude is
            free to use any ideas, concepts or know-how that you or individuals
            acting on your behalf provide to Latitude. You further recognise
            that Latitude does not want you to, and you warrant that you shall
            not, provide any information or materials to Latitude that is
            defamatory, threatening, obscene, harassing, or otherwise unlawful,
            or that incorporates the proprietary material of another.
          </p>

          <h5>Limitation of damages</h5>
          <p>
            In no event shall Latitude or any of its subsidiaries be liable to
            any entity for any direct, indirect, special, consequential or other
            damages (including, without limitation, any lost profits, business
            interruption, loss of information or programs or other data on your
            information handling system) that are related to the use of, or the
            inability to use, the content, materials, and functions of the site
            or any linked website, even if latitude is expressly advised of the
            possibility of such damages.
          </p>

          <h5>Changes</h5>
          <p>
            Latitude reserves the right, at its sole discretion, to change,
            modify, add or remove any portion of this Agreement in whole or in
            part, at any time. Changes in this Agreement will be effective when
            notice of such change is posted. Your continued use of the Site
            after any changes to this Agreement are posted will be considered
            acceptance of those changes. Latitude may terminate, change, suspend
            or discontinue any aspect of the Latitude Site, including the
            availability of any features of the Site, at any time. Latitude may
            also impose limits on certain features and services or restrict your
            access to parts or all of the Site without notice or liability.
            Latitude may terminate the authorisation, rights and license given
            above and, upon such termination, you shall immediately destroy all
            materials.
          </p>

          <h5>International users and choice of law</h5>
          <p>
            This Site is controlled, operated and administered by Latitude from
            its offices within the Commonwealth of Australia. Latitude makes no
            representation that materials at this site are appropriate or
            available for use at other locations outside of the Commonwealth of
            Australia and access to them from territories where their contents
            are illegal is prohibited. You may not use the Site or export the
            Materials in violation of Australian export laws and regulations. If
            you access this Site from locations outside of the Commonwealth of
            Australia, you are responsible for compliance with all local laws.
            These Terms and Conditions of use shall be governed by the laws of
            the State of Victoria, Australia, without giving effect to its
            conflict of laws provisions. This Agreement constitutes the entire
            agreement between Latitude Financial Services and you with respect
            to your use of the Site. Any cause of action you may have with
            respect to your use of the Site must be commenced within the period
            provided by any applicable statute of limitations. If for any reason
            a court of competent jurisdiction finds any provision of the
            Agreement or portion thereof, to be unenforceable, that provision
            shall be enforced to the maximum extent permissible so as to effect
            the intent of the Agreement, and the remainder of this Agreement
            shall continue in full force and effect.
          </p>

          <h5>Mobile application users - limitation of liability</h5>
          <p>
            If you have accessed this information via a mobile application
            program (“App”), your access to, and use of this App is at your own
            risk. Latitude acknowledges, however that certain statutory
            warranties cannot be excluded. Without limiting the above, Latitude
            makes no warranties or representations as to the accessibility,
            security, stability or reliability of this App and Latitude
            specifically disclaims any liability or responsibility for any App
            faults, failures or interruptions or the accuracy, timeliness,
            completeness, security or reliability of any communications
            (including, without limitation, any transactions) made using the
            App.
          </p>
        </RibbonSection>
      </main>
    </Layout>
  );
}

export default TermsAndConditionsPage;
